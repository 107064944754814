// react-hot-loader must be imported before react & react-dom.
import { hot } from "react-hot-loader";

import React, { useState } from "react";
import { Layout, Card, Row, Col } from "antd";
import I18n from "i18n-js";

import { t } from "./lang";
import payWithXemLogo from "./assets/img/pay_with_xem.png";
import XEMCheckout from "./components/XEMCheckout";

const CardTitle = () => (
  <div style={{ textAlign: "center" }}>
    <div>
      <img src={payWithXemLogo} style={styles.headerImage} alt={t("appName")} />
    </div>
  </div>
);

const App = () => {
  const [translations, setTranslations] = useState(
    I18n.translations[I18n.locale]
  );

  return (
    <Layout style={{ height: "100%", padding: "20px 10px 80px 10px" }}>
      <Layout.Content>
        <Row>
          <Col span={24}>
            <Card
              title={<CardTitle />}
              bordered={true}
              style={{
                width: "100%",
                maxWidth: 600,
                marginLeft: "auto",
                marginRight: "auto"
              }}
            >
              <XEMCheckout />
            </Card>
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

const styles = {
  headerImage: {
    display: "inline-block",
    margin: 10,
    height: 50
  }
};

export default hot(module)(App);
