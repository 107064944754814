import React, { useEffect, useState } from "react";
import { Result, Button, Descriptions, Row, Col, Typography, Tag, Spin } from 'antd';
import qs from "query-string";
import QRCode from "qrcode.react";

const { Paragraph } = Typography;
const USE_LOCALHOST = false;
const verifyEndpoint = USE_LOCALHOST ? 'http://localhost:3000' : 'https://xempay.luxtag.io'

const requiredParams = [
  "orderId",
  "amount",
  "checkoutDesc",
  "recipientAddress",
  "networkType",
  "redirectUrl"
];

const verifyTx = async (params) => {
  const query = qs.stringify(params)
  const response = await fetch(verifyEndpoint + '/api/verify?' + query)
  let data = await response.json()
  return data
}

export default () => {

  const [awaitingPayment, setAwaitingPayment] = useState(true);
  const [unconfirmed, setUnconfirmed] = useState(false);
  const [redirectTimer, setRedirectTimer] = useState(5);

  const params = qs.parse(window.location.search);
  for (const paramName of requiredParams) {
    if (!params[paramName]) {
      return <div>error: missing param: {paramName}</div>;
    }
  }

  // Sanitize params
  params.recipientAddress = params.recipientAddress.replace(/-/g, "");
  params.amount = parseInt(params.amount * 1000000) // Need to correct to microXEMS

  const redirectConfirmation = () => {
    if (awaitingPayment) {
      return;
    }
    window.open(params.redirectUrl, '_self');
  }

  useEffect(() => {
    if(redirectTimer < 1 && !awaitingPayment) {
      redirectConfirmation()
      return;
    }
    if (!awaitingPayment && redirectTimer > 0) {
      setTimeout(() => {
        setRedirectTimer(redirectTimer - 1)
      }, 1000)
    }
  }, [awaitingPayment, redirectTimer])

  const checkInterval = setInterval(async () => {
    verifyTx(params)
    .then(confirmation => {
      if (confirmation.ok) {
        setAwaitingPayment(false);
        clearInterval(checkInterval);
      }
      setUnconfirmed(confirmation.unconfirmed)
    })
  }, 15000);

  const qrPayload = {
    v: params.networkType === "testnet" ? 1 : 2,
    type: 2,
    data: {
      addr: params.recipientAddress,
      amount: params.amount, // Need to correct to microXEMS
      msg: JSON.stringify({ orderId: params.orderId }),
      name: params.checkoutDesc
    }
  };

  return awaitingPayment ?
    <Row gutter={[0, 16]}>
      <Col>
        <Tag style={styles.customTag} color="red">
          <b>Notice</b>: Do not close this page until you are redirected to the confirmation page
        </Tag>
      </Col>
      <Col>
        <Row type="flex" justify="center" align="middle">
          <Col>
            <QRCode value={JSON.stringify(qrPayload)} />
          </Col>
        </Row>
      </Col>
      <Col>
        <Tag style={styles.customTag}>
          <b>Note</b>: Copy and paste the following details in NanoWallet or scan the above QR code with your NEM Wallet
        </Tag>
      </Col>
      <Col>
        <Descriptions column={1} bordered title="" size="large">
          <Descriptions.Item label="Address">
            <Paragraph style={styles.customParaghraph} ellipsis copyable>{params.recipientAddress}</Paragraph>
          </Descriptions.Item>
          <Descriptions.Item label="Amount">
            <Paragraph style={styles.customParaghraph} copyable>{params.amount/1000000}</Paragraph>
          </Descriptions.Item>
          <Descriptions.Item style={styles.customParaghraph} label="Message">
            <Paragraph ellipsis copyable>
              {JSON.stringify({ orderId: params.orderId })}
            </Paragraph>
          </Descriptions.Item>
          <Descriptions.Item style={styles.customParaghraph} label="Message type">Encrypted/Unencrypted</Descriptions.Item>
        </Descriptions>
      </Col>
      {
        unconfirmed &&
        <Col>
          <Tag style={styles.customTag} color="blue">
            Pending transactions found. Waiting for block confirmation
          </Tag>
        </Col>
      }
      <Col>
        <Row type="flex" justify="end" align="middle">
          <Col>
            <Button type="primary" size="large" onClick={redirectConfirmation} loading={awaitingPayment}>
              {awaitingPayment ?
                'Waiting for Payment' :
                'Proceed to Confirmation Page'}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row> :
    <Result
      status="success"
      title="Your payment was successful"
      subTitle={`You will be redirected to the confirmation page in ${redirectTimer} seconds.`}
      extra={
        <Button type="primary" size="large" onClick={redirectConfirmation}>
          Proceed to Confirmation Page
        </Button>
      }
    />
};

const styles = {
  customParaghraph: {
    marginBottom: 0,
  },
  customTag: {
    whiteSpace: 'normal',
    padding: '8px 16px',
    fontSize: 14,
    margin: 0,
    width: '100%',
  }
};
